import React, { Component } from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import AOS from "aos"
import 'aos/dist/aos.css';
import newsArticleOne from "../../images/news/wakefield-free-bus.jpg"
import NewsStrip from "../../components/news-strip"

class NewsPage extends Component {

  componentDidMount(){
    AOS.init();
    window.addEventListener('load', AOS.refresh);
    // @NOTE: Hack to force AOS to work :(
    window.setTimeout(AOS.refresh, 1000);
  }

  componentDidUpdate() {
    AOS.refresh();
  }

  render() {
    return (
      <Layout>
        <SEO title="Tetley's Coaches relaunch free Wakefield bus service" description="Wakefield’s Free City Bus Service was relaunched last week with a fleet of ‘new, modern vehicles’ in a bid to make journeys more comfortable for passengers." />
        <div className="block-services inner-body-bg">
          <div className="body gutter page-content-parent page-article" style={{ background: 'white' }}>
            <div>
              <h1 className="page-content-heading">Tetley's Coaches relaunch free Wakefield bus service</h1>
              <p className="news-article-date">October 2017</p>
            </div>

            <div className="news-article-body">
              <p className="news-article-img">
                <img src={newsArticleOne} alt="" />
              </p>
              <p>Wakefield’s Free City Bus Service was relaunched last week with a fleet of ‘new, modern vehicles’ in a bid to make journeys more comfortable for passengers.</p>
              <p>The service, which has been operating since 2007, provides transport for commuters and shoppers around the city centre – and is now run by Tetley Motor Services.</p>
              <p>The Euro VI-compliant Optare Solo SRs will be used by hundreds of passengers each day, cutting the number of cars on the city’s roads.</p>
              <p>Cllr Denise Jeffery, Cabinet Member for Economic Growth and Regeneration at Wakefield Council, said:</p>
              <p>“The free bus service is now in its eleventh year and continues to be very popular. I am pleased that passengers will be able to enjoy the comfort of new, modern vehicles as it’s a great way for commuters, shoppers and visitors to get around the city.”</p>
              <p>The service is funded by Wakefield Council and by the West Yorkshire Combined Authority.</p>
              <p>Cllr Keith Wakefield, Chair of the West Yorkshire Combined Authority Transport Committee, said:</p>
              <p>“Since they were introduced in 2007, Wakefield’s Free City Buses have clocked up over one million miles – the equivalent of about four journeys to the Moon – and have been used by around four million people to travel between the city’s main transport, shopping, leisure and cultural locations.</p>
              <p>“These popular Free Bus services, which the Combined Authority also supports in Dewsbury and Huddersfield, help support local economies and ease congestion, and I am pleased to see that the new vehicles have been built by Leeds City region company Optare.”</p>
              <p>Ian Tetley, Managing Director of Tetley’s Motor Services, said:</p>
              <p>“Tetley’s Motor Services are delighted to have the opportunity of working in partnership with Wakefield Council and WYCA to continue a popular and well patronised service. The new Optare Solos have the very latest Euro VI diesel engine technology with significantly reduced emissions.”</p>
            </div>
          </div>
        </div>

        <NewsStrip />

      </Layout>
    );
  }
}

export default NewsPage
